interface CustomError {
  message: string;
}

export interface Application {
  name: string;
  title: string;
  current: string;
  environment: string;
  configRepoURL: string;
  valuesFile: string;
  baseBranch: string
  tags: Tag[];
  repoOwner: string,
  repoName: string,
}

export interface Tag {
  id: string;
  createdAt: Date;
}

export interface User {
  id: number;
  user:  string;
  name:  string;
  email: string;
}

export async function GetApps(accessToken: string): Promise<[Application[], CustomError | null]> {
    // TODO: load url from env
  const resp = await fetch(process.env.REACT_APP_API_URL + '/v1/apps', {
    mode: 'cors',
    headers: {
      'Authorization': accessToken,
      'Access-Control-Allow-Origin': '*'
    }
  });

  if (!resp.ok) {
    return [[], { message:resp.status + " " + resp.statusText }];
  }

  const data = await resp.json();

  const apps = data.map((item: any) => {
    const tags = item.Tags || []

    const parsedTags = tags.map((t: any) => {
      return {
        id: t.Id,
        createdAt: parseDate(t.CreatedAt),
      };
    })

    return {
      name: item.Name,
      title: item.Title,
      current: item.CurrentTag,
      environment: item.Environment,
      tags: parsedTags,
      baseBranch: item.TargetBranch,
      valuesFile: item.ValuesFile,
      repoOwner: item.RepoOwner,
      repoName: item.RepoName,
    } as Application;
  });

  return [apps, null];
}

const parseDate = (dateString: string) => {
  return new Date(Date.parse(dateString));
};

export async function OpenPR(user: User, token: string, app: Application, tag: Tag): Promise<[string, CustomError | null]> {
  const body = {
    tag: tag.id,
    current_tag: app.current,
    app: app.name,
    env: app.environment,
    user: user.user,
    name: user.name,
    email: user.email,
    base_branch: app.baseBranch,
    values_path: app.valuesFile,
    repo_owner: app.repoOwner,
    repo_name: app.repoName,
  };

  const resp = await fetch(process.env.REACT_APP_API_URL + "/v1/pr/open", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body)
  });

  if (!resp.ok) {
    return ["", { message:resp.status + " " + resp.statusText }];
  }
  
  const prURL = await resp.json();

  return [prURL, null]
}

export async function GetUser(token: string): Promise<[User, CustomError | null]> {
  const resp = await fetch(process.env.REACT_APP_API_URL + "/v1/user", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": token,
    },
  });

  if (!resp.ok) {
    return [{user: "", name: "", email: "",} as User, { message:resp.status + " " + resp.statusText }];
  }

  const user = await resp.json();

  const u = {
    id: user.id,
    user: user.login,
    name: user.name,
    email: user.email,
  } as User;

  return [u, null]
}
