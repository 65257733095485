import { AppBar, Box, Button, Divider, getFormLabelUtilityClasses, List, ListItemButton, ListItemText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Application, GetApps, OpenPR, User, GetUser } from './jason'

const App: React.FC = () => {
  const [data, setData] = React.useState<Application[]>([]);
  const [activeTab, setActiveTab] = React.useState<string>('staging');
  const [selectedAppName, setSelectedAppName] = React.useState<string>('');
  const [user, setUser] = React.useState<User>({user: "", name: "", email: "",} as User)
  const { token } = useParams()

  const getApps = () => {
    return data.filter(d => d.environment === activeTab && d.tags.length);
  }

  const onSelectApp = (appName: string) => {
    setSelectedAppName(appName);
  }

  const changeTab = (_: any, value: string) => {
    setActiveTab(value);
  }

  React.useEffect(() => {
    const fetchApps = async (accessToken: string) => {
      const [apps, err] = await GetApps(accessToken)
      if (err) {
        console.log(err)
        return
      }

      setData(apps);
    }

    const fetchUser = async (accessToken: string) => {
      const [user, err] = await GetUser(accessToken)
      if (err) {
        console.log(err)
        return
      }

      setUser(user);
    }


    let t = token || "" 

    fetchApps(t)
    fetchUser(t)
  }, [token]);

  const getAppForActiveEnv = (): Application | undefined => {
    return data.find(d => d.name === selectedAppName && d.environment === activeTab && d.tags.length);
  };

  return (    
    <Box>
      <AppBar position="static">
        <Box
          display={'flex'}
          alignItems={'center'}
          m={3}
          justifyContent={'space-between'}
        >
          <Typography variant="h5" ml={3} letterSpacing={'0.4em'}>
            JASON
          </Typography>
          <Tabs
            value={activeTab}
            onChange={changeTab}
          >
            <Tab value="staging" label="Staging" />
            <Tab value="production" label="Production" />
          </Tabs>
        </Box>
      </AppBar>

      <Box>
        <Box
          display={'flex'}
          flexDirection="row"
          m={4}
        >
          <Box
            m={2}
            display={'flex'}
            flexDirection={'column'}
            flex={1}
          >
            <Typography variant="h6" mb={2}>
              Select app:
            </Typography>
            <Divider />
            <List>
              {
                getApps()
                  .sort((a, b) => a.title > b.title ? 1 : -1)
                  .map(a => (
                    <ListItemButton
                      onClick={() => onSelectApp(a.name)}
                      selected={a.name === selectedAppName}
                      key={a.name}
                    >
                      <ListItemText primary={a.title} />
                    </ListItemButton>
                  ))
              }
            </List>
          </Box>
          <Box
            m={2}
            display={'flex'}
            flex={4}
            justifyContent='center'
          >
            <Tags
              app={getAppForActiveEnv()}
              user={user}
              token={token || ""}
            />
          </Box>
        </Box>
      </Box>

    </Box>
  );
}

interface TagsProps {
  app: Application | undefined;
  user: User;
  token: string;
}

const Tags: React.FC<TagsProps> = ({ app, user, token }) => {
  if (!app) {return null;}
  return (
    <Box>
      <Typography variant="h6" component="h2" mb={2}>
        {app.title} - {app.environment} : {app.current}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tag</TableCell>
              <TableCell align="right">Created at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {app.tags.map((tag) => (
              <TableRow
                key={tag.id}
              >
                <TableCell scope="row">
                  {tag.id}
                </TableCell>
                <TableCell align="right">{ tag.createdAt.toLocaleString() }</TableCell>
                <TableCell scope="row">
                  <DeployButton
                    onOpenPR={() => OpenPR(user, token, app, tag) }
                    env={app.environment}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

interface CustomError {
  message: string;
}

interface DeployButtonProps {
  onOpenPR: () => Promise<[string, CustomError | null]> ;
  env: string;
}

const DeployButton: React.FC<DeployButtonProps> = ({onOpenPR, env}) => {
  const [status, setStatus] = React.useState('idle');
  const [prUrl, setPR] = React.useState("");

  const openPR = async () => {
    if (status === 'success') {
      window.open(`${prUrl}`, '_blank');
      return;
    }
    setStatus('working')
    const response = await onOpenPR();
    if (response[1]) {
      setStatus('error')
    } else {
      setPR(response[0])
      setStatus('success')
    }
  }

  const getLabel = () => {
    if (status === 'idle') {
      return 'Open PR!'
    }
    else if (status === 'working') {
      return 'Working. Hold on!';
    }
    else if (status === 'success') {
      return `Done! Go to PR.`;
    }
    else if (status === 'error') {
      setTimeout(() => {
        setStatus('idle')
      }, 4000);
      return `Something went wrong!`;
    }
  }

  return (
    <Button
      disabled={ ['working', 'error'].includes(status) }
      onClick={() => { openPR() } }
    >
      { getLabel() }
    </Button>
  );
};

export default App;
